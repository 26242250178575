import { createRouter, createWebHashHistory } from 'vue-router'


const routes = [
  {
    // 访问根路径重定向到/home
    path: '/',
    redirect: '/index'
  },
  {
    path: '/index',
    name: 'index',
    component: () => import('../views/index'),
    meta:{
      keepAlive:true // 需要缓存
    }
  },
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
